import React, { useEffect } from "react";

import Header from "./components/Header";

import "./App.css";
import { HelmetProvider } from "react-helmet-async";
import AppRouter from "./AppRouter.js";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Header />
        <AppRouter />
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
