import { helmetJsonLdProp } from "react-schemaorg";
import { Helmet } from "react-helmet-async";

export default function JSONLDHelmet({
  // canonicalUrl,
  jobDetails: {
    title = "",
    description = "",
    postedDate = "",
    closeDate = "",
    employmentType = "",
    keywords = "",
  },
}) {
  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  return (
    <>
      <Helmet
        script={[
          helmetJsonLdProp({
            "@context": "https://schema.org/",
            "@type": "JobPosting",
            title,
            description,
            datePosted: postedDate,
            validThrough: closeDate,
            applicantLocationRequirements: [
              {
                "@type": "Country",
                name: "USA",
              },
              ...states.map((state) => ({
                "@type": "State",
                name: `${state}, USA`,
              })),
            ],
            jobLocationType: "TELECOMMUTE",
            employmentType,
            hiringOrganization: {
              "@type": "Organization",
              name: "Flow Global Technologies Inc.",
              sameAs: "https://www.flowai.tech/",
              logo: "https://www.example.com/images/logo.png",
            },
          }),
        ]}
      >
        <meta charSet="utf-8" />
        <title>Flow Careers | {title}</title>
        <meta
          name="keywords"
          content={`Flow, Flow Ai, Sales, Sales Orchestration, All-in-one Sales platform, Careers, Jobs, ${keywords}`}
        />
      </Helmet>
    </>
  );
}
